var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("schema-form-data-viewer", {
        attrs: {
          "header-text": null,
          "schema-viewer-data": _vm.dataViewerData,
          form: _vm.form,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }