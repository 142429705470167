// Data Type helpers -------------------------------------------------------------------------->
import _ from 'lodash'

export function isStringFormData(_index, item) {
  return typeof (item) !== 'object'
}

export function isRegisteredAgentFormData(index, item) {
  return (index?.indexOf('official') === 0 || index === 'registered_agent')
    && isRegisteredAgent(item)
}

export function isPersonObject(index, item) {
  const validPersonIndexNames = ['contact_person', 'filer']
  return validPersonIndexNames.includes(index) || isOfficial(item)
}

export function isPersonFormData(index, item) {
  return isPersonObject(index, item) || index?.indexOf('official') === 0
}

export function isAddressFormData(index, item) {
  return isAddress(item)
}

export function isObjectFormData(_index, item) {
  return typeof (item) === 'object'
}

export function valueIsBooleanString(value) {
  return value === 'false' || value === 'true'
}

export function isAddress(item) {
  return item && Object.keys(item).includes('line1')
}

export function isRegisteredAgent(registeredAgent) {
  return isAddress(registeredAgent.address) && registeredAgent.name
}

export function displayKey(key) {
  return _.startCase(_.toLower(key))
}

export function isOfficial(item) {
  return item && (item.hasOwnProperty('first_name') || item.hasOwnProperty('name'))
}

export function isManager(index) {
  return index.match('manager')
}

export function isString(item) {
  return item && typeof item === 'string'
}

export function displayPhoneNumber(phone) {
  return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`
}

export function dataType(index = null, item) {
  let dataType

  if (isStringFormData(index, item))                dataType = 'string'
  else if (isRegisteredAgentFormData(index, item))  dataType = 'registered-agent'
  else if (isAddressFormData(index, item))          dataType = 'address'
  else if (isPersonFormData(index, item))           dataType = 'person'
  else if (isObjectFormData(index, item))           dataType = 'object'

  return dataType
}

export function trimDuplicates(data) {
  const returnData = Object.assign({}, data)
  for (let [key, val] of Object.entries(data)) {
    if (key.toLowerCase() === 'official' && val?.role) {
      const potentialDuplicate = `official.${val.role.toLowerCase()}`
      if (
        Object.keys(data).includes(potentialDuplicate) &&
        val.first_name === data[potentialDuplicate].first_name &&
        val.last_name === data[potentialDuplicate].last_name
      ) {
        delete returnData[key]
      }
    }
  }
  return returnData
}

export function filterBooleanFields(formData) {
  return Object.fromEntries(Object.entries(formData).filter(([_key, value]) => {
    return typeof value === 'boolean' || valueIsBooleanString(value)
  }))
}

export function filterNonBooleanFields(formData) {
  return Object.fromEntries(Object.entries(formData).filter(([_key, value]) => {
    return typeof value !== 'boolean' && !valueIsBooleanString(value)
  }))
}

export function metaSuggestionRAAddress(index, form) {
  let raAddress = null
  const formField = form?.find(field => field.name === index)
  if (formField?.meta?.type === 'person') {
    raAddress = formField?.fields?.find(field => field.name === 'address')
  } else if (formField?.meta?.type === 'address') {
    raAddress = formField
  }
  return raAddress?.meta?.suggestion || null
}

export function normalizeOfficial(officialKey, official, officialParts) {
  // Only need to include fields collected on the form to the Data Viewer
  const filteredOfficial = Object.keys(official)
    .filter(key => officialParts.has(key))
    .reduce((obj, key) => {
      obj[key] = official[key]
      return obj
    }, {})

  if (!filteredOfficial.role && officialKey.includes('official.')) {
    let role = officialKey.split('official.')[1]
    filteredOfficial.role = role.charAt(0).toUpperCase() + role.slice(1)
  }
  return filteredOfficial
}
