<template>
  <div>
    <schema-form-data-viewer
      :header-text="null"
      :schema-viewer-data="dataViewerData"
      :form="form"
    />
  </div>
</template>

<script>
import * as helper from '@/components/SchemaForm/helper'
import { normalizeOfficial } from '@/components/SchemaForm/schemaViewerHelper'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { epochTimeConverter } from '@/common/modules/formatters'

export default {
  name: 'ViewDetails',
  components: {
    SchemaFormDataViewer: () => import('@/components/SchemaForm/SchemaFormDataViewer.vue'),
  },
  props: {
    schema: {
      type: Array,
      default: () => [],
    },
    companyDetailsItem: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      loading: false,
      form: {},
      formValues: {},
      suggestionFields: [],
      cachedFormValues: {},
    }
  },
  computed: {
    ...mapGetters('account', [
      'people',
    ]),
    ...mapGetters('stagelineCompanyOverview', [
      'company',
    ]),
    dataViewerData () {
      const data = {}
      if (!this.form.length) return data

      this.form.forEach(item => {
        if (!['hidden', 'disclaimer'].includes(item?.type)) {
          if (item.name.includes('official.') || item.name === 'official') {
            if (item.name === 'official' && item?.subtype) {
              item.name = item.name + "." + item.subtype.toLowerCase()
            }

            const officialArray = []
            const fieldParts = new Set(item.form_fields?.map(field => field.name).filter(Boolean) || [])
            for (const [formValuesKey, formValuesValue] of Object.entries(this.formValues)) {
              if (formValuesKey.includes(item.name)) {
                Array.isArray(formValuesValue) ?
                  formValuesValue.forEach(official => {
                    const normalizedOfficial = normalizeOfficial(formValuesKey, official, fieldParts)
                    officialArray.push(normalizedOfficial)
                  }) : officialArray.push(normalizeOfficial(formValuesKey, formValuesValue, fieldParts))
              }
            }
            data[item.name] = officialArray
          } else {
            const incompleteData =
                (item.name === 'filer' && !(this.formValues[item.name]?.first_name || this.formValues[item.name]?.last_name)) ||
                (item.name === 'registered_agent' && !this.formValues[item.name]?.name)
            data[item.name] = !this.formValues[item.name] || incompleteData ? '-' : this.formValues[item.name]
          }
        }
      })
      return data
    },
  },
  async mounted () {
    this.loading = true
    this.setForm()
    await this.hydrateForm()
    this.loading = false
  },
  methods: {
    setForm() {
      this.form = this.schema
    },
    async hydrateForm() {
      let details = this.companyDetailsItem.company.details
      details = this.fillDetailsFromDomesticRegistration(details)

      this.formValues = {}
      const [formValues, suggestionFields] = helper.fillFormFromDetails(this.form, details)
      if (!_.isEmpty(formValues)) {
        this.formValues = formValues
        this.formValues = helper.normalizeOfficials(this.form, this.formValues)
        this.form.values = this.formValues
      }
      if (Object.keys(suggestionFields).length) this.suggestionFields = suggestionFields
      // Cache original form values in case the user wants to clear their changes
      this.cachedFormValues = this.formValues
    },
    fillDetailsFromDomesticRegistration(details) {
      if (details.home_state !== this.company?.domestic_registration?.jurisdiction?.state_province_region) {
        details.home_state = this.company.domestic_registration?.jurisdiction?.state_province_region
      }

      if (this.company?.domestic_registration?.file_date) {
        const convertedFileDate = epochTimeConverter(this.company.domestic_registration.file_date)
        if (details.formation_date !== convertedFileDate) {
          details.formation_date = convertedFileDate
        }
      }

      if (details.state_entity_id !== this.company?.domestic_registration?.entity_id) {
        details.state_entity_id = this.company.domestic_registration.entity_id
      }

      return details
    },
  },
}
</script>
